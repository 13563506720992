<template>
  <div class="vap-page">
    <div class="vap-page-content">
      <div v-if="loadingScannerPage === true">
        <!-- If this is not here, the no-items-div flashes for a second while the form list loads -->
      </div>
      <div v-else-if="scannerAvailable" class="scanner-container">
        <div v-if="workflowList && workflowList.length > 0" class="scanner-option" @click="toggleWorkflowSelect">
          <VasionHomeWorkflow />
          <label class="scan-label">Scan to Workflow</label>
        </div>
        <div class="scanner-option" @click="toggleBrowseFolderDialog">
          <VasionHomeStorage />
          <label class="scan-label">Scan to Storage</label>
        </div>
      </div>
      <div v-else>
        <EmptyCapture @iHaveAScannerClicked="iHaveAScannerClick" />
      </div>
      
      <md-dialog id="start-workflow-select-dialog" :md-active.sync="showWorkflowSelect" :md-click-outside-to-close="false">
        <VasionSelectWorkflow :workflowList="workflowList" @cancelButtonClick="toggleWorkflowSelect" @continueButtonClick="continueToScanner" />
      </md-dialog>

      <md-dialog id="browse-folders-dialog" :md-active.sync="showBrowseFoldersDialog" :md-click-outside-to-close="false">
        <div class="folder-dialog-browse">
          <VasionFolders />
        </div>
        <div class="align-right">
          <VasionButton
            id="browse-cancel"
            classProp="secondary"
            @vasionButtonClicked="toggleBrowseFolderDialog()"
          >
            Cancel
          </VasionButton>
          <VasionButton
            id="browse-ok"
            classProp="primary"
            @vasionButtonClicked="folderDialogOK"
          >
            OK
          </VasionButton>
        </div>
      </md-dialog>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

import EmptyCapture from '@/components/capture/EmptyCapture.vue';

export default {
  name: 'StartScan',
  components: {
    EmptyCapture,
  },
  data: function () {
    return {
      folderId: 0,
      loadingScannerPage: true,
      scannerAvailable: false,
      scanType: '',
      showBrowseFoldersDialog: false,
      showWorkflowSelect: false,
      workflowList: [],
    }
  },
  computed: {
    selectedFolder() { return this.$store.state.common.selectedFolder },
    selectedWorkflow() { return this.$store.state.workflow.selectedWorkflow },
  },
  watch: {
    scannerAvailable: function () {
      if (this.scannerAvailable) {
        this.getWorkflowMenuItemsFiltered()
      }
    },
  },
  async created() {
    if (this.$route.params.haveScanner) {
      this.scannerAvailable = true
    }

    this.loadingScannerPage = false
  },
  methods: {
    async continueToScanner(workflowID) {
      if (this.scanType === 'workflow') { // Starting scan via workflow
        this.startWorkflowScan(workflowID)
        this.toggleWorkflowSelect()
        await this.$amplitude.trackEvent('Workflow Started', {
          'initiationType': 'Scan',
        })
      } else { // Starting scan via storage
        this.startStorageScan()
        this.toggleBrowseFolderDialog()
      }
    },
    folderDialogOK() {
      if (this.selectedFolder
        && this.selectedFolder.isSelected === true) {
        this.folderId = this.selectedFolder.item.FolderId
      } else {
        this.folderId = null
      }

      if (this.folderId) {
        this.continueToScanner()
      } else {
        console.warn('Unable to find selected folder ID')
      }
    },
    async getWorkflowMenuItemsFiltered() {
      const payload = {
        includeStartFromEform: false,
        includeScan: true,
      }

      this.workflowList = await this.$store.dispatch('workflow/getWorkflowMenuItemsFiltered', payload)
    },
    iHaveAScannerClick() { this.$router.push({ name: 'StartScanManual', params: { haveScanner: true } }) },
    async startStorageScan() {
      const payload = {
        folderId: this.folderId,
        workflowId: 0,
      }
      await this.$store.dispatch('common/openScanToVasion', payload)
    },
    async startWorkflowScan(tempWorkflowId) {
      const payload = {
        folderId: 0,
        workflowId: tempWorkflowId,
      }
      await this.$store.dispatch('common/openScanToVasion', payload)
    },
    toggleBrowseFolderDialog() {
      this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog
      if (this.showBrowseFoldersDialog) {
        this.scanType = 'storage'
      }
    },
    toggleWorkflowSelect() {
      this.showWorkflowSelect = !this.showWorkflowSelect
      if (this.showWorkflowSelect) {
        this.scanType = 'workflow'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.scanner-container {
  display: flex;
  justify-content: center;
  margin-top: 13%;
}

.scanner-option {
  max-width: 560px;
  width: 100%;
  height: 315px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 $box-shadow;
  background-color: $white;
}

.scan-label {
  @include Headline;
  margin-top: 20px;
}

#browse-folders-dialog {
  .folder-dialog-browse {
    width: 400px;
    height: 300px;
    overflow: auto;
  }

  .align-right {
    text-align: right;
  }
}

@media(max-width: 600px){
  .scanner-container{
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 0px;
  }

  .scanner-option{
    width: 270px;
    height: 250px;
  }
}

@media(max-height: 630px){
  .scanner-container{
    margin-top: 0px;
  }
}
</style>
